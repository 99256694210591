.quiz-component__container {
  position: relative;
}

.quiz-component__container::before {
  @apply bg-gray-800 absolute rounded-lg;
  content: "";
  bottom: -8px;
  right: -8px;
  z-index: -1;
  width: 100%;
  height: 100%;
}

@screen md {
  .quiz-component__container::before {
    bottom: -12px;
    right: -12px;
  }
}

.quiz-component__video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.quiz-component__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
