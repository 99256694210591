@tailwind base;
@tailwind components;
@tailwind utilities;
/* Custom utilities */

.max-w-fc {
  max-width: fit-content;
}

/* Custom Style */

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  color: #33312D;
  @apply w-full antialiased bg-gray-100;
}

@screen md {
  * {
    scrollbar-width: thin;
    scrollbar-color: theme(colors.gray.500) theme(colors.gray.100);
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: theme(colors.gray.100);
  }
  ::-webkit-scrollbar-thumb {
    background-color: theme(colors.gray.500);
    border-radius: 6px;
    border: 3px solid theme(colors.gray.100);
  }
}

h1, h2, h3, h4, h5, h6 {
  @apply font-bold;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.75rem;
}

h6 {
  font-size: 0.5rem;
}

@screen md {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }
}

/* Input */

input:disabled, .disabled {
  @apply cursor-not-allowed bg-gray-200 text-gray-700;
}

/* Button */

button:focus {
  outline: none;
}

.btn {
  @apply inline-flex items-center justify-center text-sm font-semibold py-2 px-4 rounded-md cursor-pointer;
}

.btn:focus {
  @apply outline-none shadow-inner;
}

/* Button Styles */

.btn-subtle {
  @apply bg-gray-200 text-gray-800;
}

.btn-subtle:hover {
  @apply bg-gray-300 text-gray-900;
}

.btn-subtle:focus {
  @apply bg-gray-400 text-gray-900;
}

.btn-success {
  @apply bg-green-500 text-white;
  background-image: linear-gradient( 135deg, rgba(72, 187, 120, 1) 0%, rgba(56, 161, 105, 1) 100%);
}

.btn-success:hover {
  @apply bg-green-600;
  background-image: linear-gradient( 135deg, rgba(56, 161, 105, 1) 0%, rgba(47, 133, 90, 1) 100%);
}

.btn-success:focus {
  @apply bg-green-800;
  background-image: none;
}

.btn-warning {
  @apply bg-yellow-500 text-white;
  background-image: linear-gradient( 135deg, rgba(237, 137, 54, 1) 0%, rgba(221, 107, 32, 1) 100%);
}

.btn-warning:hover {
  @apply bg-yellow-600;
  background-image: linear-gradient( 135deg, rgba(221, 107, 32, 1) 0%, rgba(192, 86, 33, 1) 100%);
}

.btn-warning:focus {
  @apply bg-yellow-800;
  background-image: none;
}

.btn-danger {
  @apply bg-red-500 text-white;
  background-image: linear-gradient( 135deg, rgba(245, 101, 101, 1) 0%, rgba(229, 62, 62, 1) 100%);
}

.btn-danger:hover {
  @apply bg-red-600;
  background-image: linear-gradient( 135deg, rgba(229, 62, 62, 1) 0%, rgba(197, 48, 48, 1) 100%);
}

.btn-danger:focus {
  @apply bg-red-800;
  background-image: none;
}

button:disabled, .disabled {
  @apply cursor-not-allowed bg-gray-300 text-gray-500 shadow-none border-transparent;
  background-image: none;
}

button:disabled:hover, .disabled:hover, button:disabled:focus, .disabled:focus {
  @apply bg-gray-300 text-gray-500 border-transparent shadow-none;
  background-image: none;
}

.btn-normal {
  @apply py-1 px-4 text-sm;
}

.btn-large {
  @apply py-2 px-5 text-base;
}

.button-xl {
  @apply py-2 px-5 text-base;
}
